const CARSALE = [
    {
        url: 'A-class.jpeg',
        name: 'Mercedez-Benz',
        desc: 'A-Class',
        offer: '$88,000',
        deal: '$93,000',
        km: '16,663 km',
        area: 'Victoria'
    },
    {
        url: 'jeep.jpeg',
        name: 'Jeep',
        desc: 'Cherokee',
        offer: '$32,000',
        deal: '$35,200',
        km: '19,000 km',
        area: 'Western Australia'
    },
    {
        url: 'nissan.jpg',
        name: 'Nissan',
        desc: 'Pathfinder',
        offer: '$12,000',
        deal: '$15,000',
        km: '194,565 km',
        area: 'Western Australia'
    },
    {
        url: 'ford.jpeg',
        name: 'Ford',
        desc: 'Falcon',
        offer: '$28,000',
        deal: '$30,622',
        km: '135,000 km',
        area: 'Victoria'
    },
    {
        url: 'mitsubishi.jpg',
        name: 'Mitsubishi',
        desc: 'ASX',
        offer: '$23,500',
        deal: '$26,100',
        km: '37,000 km',
        area: 'Western Australia'
    },
    {
        url: 'toyota.jpeg',
        name: 'Toyota',
        desc: 'Yaris',
        offer: '$6,000',
        deal: '$8,149',
        km: '156,028 km',
        area: 'Victoria'
    },
    {
        url: 'holden.jpeg',
        name: 'Holden',
        desc: 'Ute',
        offer: '$33,000',
        deal: '$35,000',
        km: '91,000 km',
        area: 'Victoria'
    },
    {
        url: 'isuzu.jpeg',
        name: 'Isuzu',
        desc: 'MU-X',
        offer: '$42,000',
        deal: '$44,000',
        km: '75,624 km',
        area: 'Victoria'
    },
    {
        url: 'kia.jpg',
        name: 'Kia',
        desc: 'Cerato',
        offer: '$7,000',
        deal: '$8,750',
        km: '82,200 km',
        area: 'Western Australia'
    },
    {
        url: 'mazda.jpeg',
        name: 'Mazda',
        desc: '3',
        offer: '$22,500',
        deal: '$23,888',
        km: '13,600 km',
        area: 'Victoria'
    },
    {
        url: 'toyota-prado.png',
        name: 'Toyota',
        desc: 'Land Cruiser Prado',
        offer: '$72,000',
        deal: '$73,250',
        km: '19,000 km',
        area: 'New South Wales'
    },
    {
        url: 'kia-cerato.jpeg',
        name: 'Kia',
        desc: 'Cerato',
        offer: '$20,500',
        deal: '$21,700',
        km: '38,000 km',
        area: 'Victoria'
    }
]

const QUESTIONS = [
    {
        id: 'question-1',
        question: `Where is AutoFlip based?`,
        answer: `AutoFlip is based in Australia - with offices in Melbourne, Sydney, and Perth.`,
        isActive: false
    },
    {
        id: 'question-2',
        question: `How long has AutoFlip been around for?`,
        answer: `AutoFlip is a subsidiary of IDOM Inc. that has over 25 years' experience in the used car industry.`,
        isActive: false
    },
    {
        id: 'question-3',
        question: `Do you have buyers all over Australia?`,
        answer: `Yes, our network of dealers buys cars across Australia.`,
        isActive: false
    },
    {
        id: 'question-4',
        question: `I have got my car advertised online already, how can you get me a better deal?`,
        answer: `At AutoFlip, we tap into our extensive network of trusted dealers across Australia and negotiate the best price for your car fast.<br/><br/>Unlike other online platforms, we can offer you the highest price fast, so that you don't have to deal with timewasters.`,
        isActive: false
    },
    {
        id: 'question-5',
        question: `What is the difference between using AutoFlip and selling directly to a car dealership?`,
        answer: `If you sell your car directly to a dealership, you have to drive around to multiple dealers to compare their offers, which can be frustrating and time-consuming.<br/><br/>With AutoFlip, you can do this from the comfort of your home! We submit your car to our large network of dealers who put forward competitive bids and offer you the best price. We even arrange the car pick-up from your home so there is no need to leave your couch.`,
        isActive: false
    },
    {
        id: 'question-6',
        question: `Are there any costs to selling my car through AutoFlip?`,
        answer: `It's free to advertise your car on AutoFlip.<br/><br/>And if you successfully sell your car to our network of dealers, we charge a success fee. The success fee amount will vary depending on the final sale amount of your car.`,
        isActive: false
    },
    {
        id: 'question-7',
        question: `How do I get paid?`,
        answer: `Once we find the best price for your car, the buyer will get in touch with you to arrange the pick-up and cash transfer.`,
        isActive: false
    },
    {
        id: 'question-8',
        question: `Can you buy my car if I still owe money through finance?`,
        answer: `Yes, we can assist by liaising with the buyer and your finance company. We'll ensure that any outstanding finances owed are paid and that the remaining amount is paid directly to you.`,
        isActive: false
    },
    {
        id: 'question-9',
        question: `How does AutoFlip estimate the value of my car?`,
        answer: `We can assess your car's estimated price based on the details that you provide about your car, such as odometer reading, internal and external condition, damage, accessories, etc. Please note that the estimate is based on the market values from across Australia.`,
        isActive: false
    },
    {
        id: 'question-10',
        question: `How is my car's final sale price determined?`,
        answer: `The final value of your car is determined by the highest offer by our trusted network of dealers. Sale prices can vary depending on your car's conditions, accessories, etc., as well as current market demand.`,
        isActive: false
    }
]

const TESTIMONIAL = [
    {
        feedback: `AutoFlip is amazing! I sold my used car through their services recently and was very impressed! It's an easy process and I'd recommend it to anyone!<br/><br/>Thanks again AutoFlip!`,
        name: 'Loretta H.',
    },
    {
        feedback: `I cannot speak highly enough about the stress-less and smooth method of selling my car! The contacts and obviously the trust [AutoFlip] has built in the industry has been shown through the quick response and sale!`,
        name: 'Michael C.',
    },
    {
        feedback: `In 24 hours my car was sold, money in the bank account, and the car was collected.<br/><br/>Yep, it all seemed a bit of a fairy-tale, but I can assure you I'll never advertise my cars again on any other forum.`,
        name: 'Jip B.',
    },
    {
        feedback: `My experience with AutoFlip was an easy and trouble-free experience - the process was quick & successful - it was completed within a few days with a successful sale of my vehicle.`,
        name: 'John B.',
    },
    {
        feedback: `This is the second vehicle [AutoFlip] has sold for us now. Once again, the car sold within a few hours, and we got more than expected.<br/><br/> The whole process was stress free and easy, we were contacted by the dealer, and the car was paid for and collected. We would recommend [AutoFlip] to anybody selling their car.`,
        name: 'Bobby B.',
    },
    {
        feedback: `I had my car for sale privately for a few months with no interest. As soon as [AutoFlip] took over and worked their magic I ended up with more cash in my pocket. It was super easy.<br/><br/> I will definitely use AutoFlip again...<br/><br/>Thanks for your great service!`,
        name: 'Natasha B.',
    },
    {
        feedback: `Absolute champions! [AutoFlip] called me the same day I submitted the inquiry. Car was up for sale that day and had an offer at the reserve I set the following day.<br/><br/> AutoFlip arranged for someone to collect it and I was paid in full the following day.<br/><br/> Would 100% recommend!`,
        name: 'Luke W.',
    },
    {
        feedback: `I was recommended AutoFlip by a colleague and was BLOWN AWAY!<br/><br/>...not even 48 hours, very limited effort and a price we are so happy with! I would 10/10 recommend AutoFlip and use them again - WOW`,
        name: 'Cameron L.',
    },
]

export default {
    CARSALE: CARSALE,
    QUESTIONS: QUESTIONS,
    TESTIMONIAL: TESTIMONIAL,
}

export const CAR_REVIEWS = [
    {
        img: 'man_review.png',
        name: 'Rochelle',
        area: 'Western Australia',
        car: 'Hyundai i20',
        review: `Extremely prompt replying to emails and answering questions. 
        A great way to sell, especially when you aren't located in a big city, where selling privately might be more difficult. 
        Definitely recommend them.`
    },
    {
        img: 'man_review_2.png',
        name: 'Lisa',
        area: 'Western Australia',
        car: 'Toyota Corolla',
        review: `What a fantastic company! 5 stars from us. Not only was our car sold the same day but the customer service we received was outstanding. We would definitely recommend if you are looking for a hassle free way to sell your car!`
    },
    {
        img: 'man_review_3.png',
        name: 'Karyn',
        area: 'Victoria',
        car: 'Suzuki Swift',
        review: `Easy, simple and a great way to sell your car. Highly Recommend, they made the whole process hassle-free.`
    },
    {
        img: 'man_review_4.png',
        name: 'David',
        area: 'Victoria',
        car: 'Mazda MX5',
        review: `The team at AutoFlip were fantastic to deal with. The team didn't give up and found a buyer for my car, despite some challenges with it being registered interstate. The sale went through smoothly and the whole process was managed professionally and efficiently.`
    },
    {
        img: 'man_review_5.png',
        name: 'James',
        area: 'Victoria',
        car: 'Holden Barina',
        review: `VIC I live in Melbourne and tried to sell my car online. All I received was unrealistic offers from people over SMS. I then uploaded my car on AutoFlip and within 24 hours we settled on a price that was ~2k more than the best offer I had online.`
    },
    {
        img: 'man_review_6.png',
        name: 'Daniel',
        area: 'Western Australia',
        car: 'Mitsubishi Outlander',
        review: `I highly recommend giving AutoFlip a try. The whole process was so easy and the service was fantastic. Shane was awesome to deal with and helped secure the best price for my old Outlander.`
    }
]

export const VEHICLE_DETAILS = [
    {
        label: 'Odometer',
        key: 'kms'
    },
    {
        label: 'Colour',
        key: 'color'
    },
    {
        label: 'Variant',
        key: 'badge'
    },
    {
        label: 'Transmission',
        key: 'transmission'
    },
    {
        label: 'Body type',
        key: 'bodyType'
    },
    {
        label: 'Drive type',
        key: 'drive'
    },
    {
        label: 'Model year',
        key: 'model_year'
    }
]
export const GUIDE_LINE_EXTERIOR = [
    {
        imgName: 'Front.jpg',
        text: 'Front'
    },
    {
        imgName: '34th_front_left.jpg',
        text: '3/4th front (left)'
    },
    {
        imgName: 'Side_left.jpg',
        text: 'Side (left)'
    },
    {
        imgName: '34_rear_left.jpg',
        text: '3/4th rear (left)'
    },
    {
        imgName: 'Rear.jpg',
        text: 'Rear'
    },
    {
        imgName: '34_rear_right.jpg',
        text: '3/4th rear (right)'
    },
    {
        imgName: 'Side_right.jpg',
        text: 'Side (right)'
    },
    {
        imgName: '34_front_right.jpg',
        text: '3/4th front (right)'
    },
    {
        imgName: 'Tyres.jpg',
        text: 'Tyres'
    },
    {
        imgName: 'Car_roof.jpg',
        text: 'Car roof'
    }
];
export const GUIDE_LINE_INTERIOR = [
    {
        imgName: 'Odometer.jpg',
        text: 'Odometer'
    },
    {
        imgName: 'Dashboard.jpg',
        text: 'Dashboard'
    },
    {
        imgName: 'Front_seats.jpg',
        text: 'Front seats'
    },
    {
        imgName: 'Rear_seats.jpg',
        text: 'Rear seats'
    },
    {
        imgName: 'Boot.jpg',
        text: 'Bootspace'
    },
    {
        imgName: 'Keys.jpg',
        text: 'Keys'
    },
    {
        imgName: 'Owners_manual.jpg',
        text: `Owner's manual & service history`
    },
];

export const EXTERIOR_RATE = [
    {
        val: 1,
        text: "Poor",
        desc: "Car has at least one of these conditions: Has major and minor scratches and dents, tyres need replacement very soon, brake pads need replacement immediately, peeling/flaking/cracking paint, major chips/cracks on the windshield, rust, cracked/broken headlamps, warped/bent rims, or major mechanical issues."
    },
    {
        val: 2,
        text: "Average",
        desc: "Car has at least one of these conditions: Has major and minor scratches or dents, had paint work to cover damages, tyres need replacement relatively soon, brake pads need replacement soon, needs some major and minor repairs or paint, headlamps are foggy, or has minor mechanical issues."
    },
    {
        val: 3,
        text: "Good",
        desc: "Car has at least one of these conditions: Has some visible scuffs and scratches, had paint touch-ups, tyres tread are less than 50% worn, has one minor crack/chip on the windshield, or needs some minor repairs or paint. No mechanical issues."
    },
    {
        val: 4,
        text: "Very Good",
        desc: "Has more than two minor dents or scratches, minor paint damage, and near-new tyres. No mechanical issues."
    },
    {
        val: 5,
        text: "Excellent",
        desc: "Car exterior is like new and may have 1-2 minor scratches or dents. No mechanical issues."
    },
];
export const INTERIOR_RATE = [
    {
        val: 1,
        text: "Poor",
        desc: "Car has at least one of these conditions: Has larger rips or tears, majorly visible stains, major electrical (e.g., window, heating) issues, major functional issues with the entertainment/navigation system, or any other major damages in the interior that requires repairs."
    },
    {
        val: 2,
        text: "Average",
        desc: "Car has at least one of these conditions: Requires some major or minor repairs due to minor rips or tears, visible stains, more than one minor electrical (e.g., window, heating) issues, minor functional issues with the entertainment/navigation system, or visible scuffs or scratches on its system screen."
    },
    {
        val: 3,
        text: "Good",
        desc: "Car has at least one of these conditions: Has minor scratches or scuffs, minor stain on seats, one minor electrical (e.g., window, heating) issue. No functional issues with the entertainment/navigation system but its system screen has minor scuffs or scratches."
    },
    {
        val: 4,
        text: "Very Good",
        desc: "Car has at least one of these conditions: Has more than two minor scratches, has some minor scratches on its entertainment/navigation screen. No rips or tears and no stains."
    },
    {
        val: 5,
        text: "Excellent",
        desc: "Car interior is like new and may have 1-2 minor damages or scratches. Entertainment/navigation screen has no scratches."
    },
];
export const VEHICLE_CONDITIONS = [
    {
        label: 'Service history details',
        key: 'serviceHistoryDetail'
    },
    {
        label: 'Tyres needing replacement',
        key: 'tyreReplacement'
    },
];